import searchParams from '@oberoninternal/travelbase-website/dist/constants/searchParams';
import { BooleanParam, decodeQueryParams } from 'use-query-params';
import useQueryParams from '@oberoninternal/travelbase-website/dist/hooks/useQueryParams';
import { parse } from 'query-string';
import React, { FC } from 'react';
import SearchList from '@oberoninternal/travelbase-website/dist/components/SearchList';
import { PAGE_MAX } from '@oberoninternal/travelbase-website/dist/components/designsystem/Pagination';
import RentalUnits from '../RentalUnits';
import SearchWrapper from '../layout/SearchWrapper';
import removeUndefinedEntries from '@oberoninternal/travelbase-website/dist/utils/removeUndefinedEntries';
import dynamic from 'next/dynamic';
import Search from '@oberoninternal/travelbase-website/dist/components/Search';

const SearchMap = dynamic(() => import('@oberoninternal/travelbase-website/dist/components/SearchMap'), { ssr: false });

interface Props {
    queryParams: string;
}

// utility functions used by this component and the higher level getStaticProps
export const isCommaSeparated = (queryParams: string) => queryParams.match(/^(?![?]).+/g);

const AccommodationsBlock: FC<React.PropsWithChildren<Props>> = ({ queryParams }) => {
    const [{ isMap }, setQueryParams] = useQueryParams({
        isMap: BooleanParam,
    });

    if (isCommaSeparated(queryParams)) {
        return <RentalUnits queryParams={queryParams} />;
    }

    const params = removeUndefinedEntries(decodeQueryParams(searchParams, parse(queryParams)));
    return (
        <SearchWrapper>
            <Search
                amountPerPage={isMap ? 25 : PAGE_MAX}
                startParams={{ accommodationType: 'all', ...params }}
                ResultsComponent={isMap ? SearchMap : SearchList}
                contentWrapperVariant="searchList"
                buttonTypes={isMap ? ['filters', 'list'] : ['filters', 'sort', 'map']}
                onMapClick={() => setQueryParams({ isMap: true })}
                onListClick={() => setQueryParams({ isMap: false })}
                ignoreStorage
            />
        </SearchWrapper>
    );
};

export default AccommodationsBlock;
